import { Component, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormGroup, FormControl } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import * as DateRangePickerActions from '../../store/date-range-picker/date-range-picker.actions';
import { DateRangeState } from '../../store/date-range-picker/date-range-picker.reducer';



// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

/** @title Basic date range picker */
@Component({
  selector: 'date-range-picker',
  templateUrl: 'date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DateRangePickerComponent implements OnInit {
  constructor(private store: Store<DateRangeState>) { } // inject the store

  private startDateChanged = false;
  private endDateChanged = false;

  ngOnInit() {
    this.dateRangeForm.get('startDate').valueChanges
      .pipe(filter(value => value !== null))
      .subscribe(() => {
        this.startDateChanged = true;
        this.triggerFunctionIfBothDatesChanged();
      });

    this.dateRangeForm.get('endDate').valueChanges
      .pipe(filter(value => value !== null))
      .subscribe(() => {
        this.endDateChanged = true;
        this.triggerFunctionIfBothDatesChanged();
      });
  }

  triggerFunctionIfBothDatesChanged() {
    if (this.startDateChanged && this.endDateChanged) {
      this.emitDateRange();
      // Reset both flags after the function call
      this.startDateChanged = false;
      this.endDateChanged = false;
    }
  }

  dateRangeForm = new FormGroup({
    startDate: new FormControl<Date | null>(moment().startOf('month').toDate()),
    endDate: new FormControl<Date | null>(moment().hour(19).minute(0).second(0).millisecond(0).toDate()), //subtract(0, 'days').
  });

  emitDateRange() {
    const startDate = moment(this.dateRangeForm.get('startDate').value).toDate();
    const endDate = moment(this.dateRangeForm.get('endDate').value).toDate();

    // Dispatch the action to set the date range in the store
    this.store.dispatch(DateRangePickerActions.setDateRange({ startDate, endDate }));

    this.dateRangeForm.markAsUntouched();
  }
}
