<div>

  <div class="title">
    <h1>Data sent to ISUN</h1>
  </div>

  <div class="container-above-table">
    <div>
      <date-range-picker></date-range-picker>
    </div>


    <div>
      <export-to-excel-button></export-to-excel-button>
    </div>
  </div>

  <div class="container mat-elevation-z8">
    <!-- Loading spinner -->
    <div class="loading-shade" *ngIf="isLoadingResults">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <div>
      <!-- Table -->
      <div class="table-container">
        <table mat-table [dataSource]="dataSource"
               matSort matSortActive="created">

          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date the data was sent </th>
            <td mat-cell *matCellDef="let element"> {{formatDate(element.created)}} </td>
          </ng-container>

          <ng-container matColumnDef="piPointName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tag </th>
            <td mat-cell *matCellDef="let element"> {{element.piPointName}} </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
          </ng-container>

          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of reporting period (start) </th>
            <td mat-cell *matCellDef="let element"> {{formatDate(element.startDate)}} </td>
          </ng-container>

          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of reporting period (end) </th>
            <td mat-cell *matCellDef="let element"> {{formatDate(element.endDate)}} </td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
            <td mat-cell *matCellDef="let element"> {{element.value}} </td>
          </ng-container>

          <ng-container matColumnDef="unitOfMeasure">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> UOM </th>
            <td mat-cell *matCellDef="let element"> {{element.unitOfMeasure}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
      </div>


    </div>
  </div>



</div>
