
import { Component, OnInit } from '@angular/core';
import { UserAuthorizeService } from './cal-authorization/user-authorize.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public isLoggedIn: boolean = false;
  title = 'app';
  constructor(
    private userAuthorizeService: UserAuthorizeService) { }

  ngOnInit(): void {
    const $checkAccount = this.userAuthorizeService.checkAccount()
    this.isLoggedIn = this.userAuthorizeService.loggedIn;
  };

}

