import { createSelector } from '@ngrx/store';
import { DateRangeState } from './date-range-picker.reducer';


export const selectDateRange = (state: DateRangeState) => state;

export const selectStartDate = createSelector(
  selectDateRange,
  (dateRange) => dateRange.startDate
);

export const selectEndDate = createSelector(
  selectDateRange,
  (dateRange) => dateRange.endDate
);

export const selectEntireDateRange = createSelector(
  selectDateRange,
  (dateRange) => dateRange
);
