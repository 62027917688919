import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver'; // Ensure you've installed the FileSaver package
import { DateRangeState } from '../../store/date-range-picker/date-range-picker.reducer';
import { selectEntireDateRange } from '../../store/date-range-picker/date-range-picker.selector';
import { FileResponse, PiPointsClient } from '../../web-api-client';

@Component({
  selector: 'export-to-excel-button',
  templateUrl: './export-to-excel-button.component.html',
  styleUrls: ['./export-to-excel-button.component.scss']
})
export class ExportToExcelButtonComponent implements OnInit {
  startDate: Date;
  endDate: Date;
  isLoading: boolean = false;
  
  dateRange$ = this.store.select(selectEntireDateRange);
  constructor(private store: Store<DateRangeState>, private piPointsClient: PiPointsClient) { }
  ngOnInit(): void {
    // Set default dates
    this.dateRange$.subscribe(dateRange => {
      // Call the API whenever dateRange changes
      this.startDate = dateRange['DateRangePicker'].startDate;
      this.endDate = dateRange['DateRangePicker'].endDate;
    });
  }

  exportToExcel() {
    this.isLoading = true;
    this.piPointsClient.excelReport(this.startDate, this.endDate).subscribe((response: FileResponse) => {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, response.fileName || 'report.xlsx');
      this.isLoading = false;
    }, error => {
      console.error("Error downloading the file.", error);
      this.isLoading = false;
    });
  }
}
