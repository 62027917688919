import { Injectable } from '@angular/core';
import { CalAngularService, CvxClaimsPrincipal, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { map, tap } from 'rxjs/operators';
import { defer, lastValueFrom, Observable } from 'rxjs';

export interface IAadToken {
  accessToken: string
}
@Injectable({
  providedIn: 'root'
})
export class UserAuthorizeService {
  loggedIn: boolean = false;
  private _currentUserProfile: any;

  public set currentUserProfile(cvxClaims: any) {
    this._currentUserProfile = cvxClaims;
  }

  public get currentUserProfile(): any {
    return this._currentUserProfile
  }
  //Token already with Bearer word
  authBearerToken: string = '';

  constructor(
    private authService: CalAngularService) { }

  checkAccount() {
    return defer(() => this.authService.isUserSignedIn())
      .subscribe((value: boolean) => {
        this.loggedIn = value;
        if (this.loggedIn) {
          this.currentUserProfile = this.authService.cvxClaimsPrincipal;
          console.log(this.currentUserProfile);
        }
        else {
          console.log("user not yet signed in");
        }
      })
  }
  //public checkAccount() {
  //  return defer(() => this.authService.isUserSignedIn())
  //    .pipe(
  //      tap(() => {
  //        this.currentUserProfile = this.authService.cvxClaimsPrincipal
  //      })
  //    );
  //}

  //public checkAccount() {
  //  return defer(() => this.authService.isUserSignedIn())
  //    .pipe(
  //      tap((value: boolean) => {
  //        this.loggedIn = value;
  //        if (this.loggedIn) {
  //          this.currentUserProfile = this.authService.cvxClaimsPrincipal;
  //        }
  //        else {
  //          console.log("user not yet signed in");
  //        }
  //      })
  //    );
  //}

  public async getAADToken() {
    let auth = await this.authService.getAADToken();

    let auth2 = await lastValueFrom(auth).catch(err => console.log(err));
    //.catchError(error => console.log(`An error occurred ${​​​​​​​error}​​​​​​​`));
    return auth2;

  }

  login() {
    this.authService.userInitiatedSignIn().subscribe((user: CvxClaimsPrincipal) => {
      if (this.authService.isUserSignedIn()) {
        this.loggedIn = true;
      }
      else {
        this.loggedIn = false;
      }
    })
  }

  logout() {
    this.authService.userInitiatedSignOut().subscribe((value: boolean) => {
      this.loggedIn = value
    })
  }

  public getUserName(): string {
    if (this.loggedIn) {
      return this.currentUserProfile.name;
    }
    else {
      return "Unauthorize";
    }
  }

  //public getRolesOfUser(): Observable<RoleType[]> {
  //  return this.userClient.getUserProfile()
  //    .pipe(
  //      map(profile => profile.roles)
  //    )
  //}
  //public currentRoles(): string[] {
  //  return JSON.parse(localStorage.getItem('roles')) as string[];
  //}
}

