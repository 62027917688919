

export const environment = {
  production: false,
  envName: 'Development',
  cal: {
    autoSignIn: false,
    popupForLogin: false,
    instance: "https://login.microsoftonline.com/",
    tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
    graphScopes: ["User.Read", "Directory.Read.All"],

    clientId: "0bebb536-7ee6-4b99-9e87-2d63eaa30f62",
    oidcScopes: ["https://isun-test.tengizchevroil.com/user_impersonation"],
    redirectUri: "https://isun-dev.tengizchevroil.com/",
    cacheLocation: "localStorage",
  }
};

