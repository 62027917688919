import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PiPointsClient, MOEPiPointsDataDto } from '../web-api-client';
import { BehaviorSubject, merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';

import { DateRangeState } from '../store/date-range-picker/date-range-picker.reducer';
import { selectEntireDateRange } from '../store/date-range-picker/date-range-picker.selector';


export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DatePipe]
  //providers: [
  //  {
  //    provide: DateAdapter,
  //    useClass: MomentDateAdapter,
  //    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  //  },
  //  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  //],
})

export class HomeComponent implements OnInit, AfterViewInit {
  displayedColumns = ['created', 'piPointName', 'description', 'startDate', 'endDate', 'value', 'unitOfMeasure'];
  @ViewChild(MatSort) sort: MatSort;
  data: MOEPiPointsDataDto[] = [];
  dataSource = new MatTableDataSource<MOEPiPointsDataDto>(this.data);

  startDate: Date;
  endDate: Date;
  isLoadingResults = true;
  // Stream that emits whenever the data has been modified.
  dataChange: BehaviorSubject<MOEPiPointsDataDto[]> = new BehaviorSubject<MOEPiPointsDataDto[]>([]);

  //dateRange$ = this.store.pipe(
  //  select(selectEntireDateRange),
  //  tap(range => {
  //    if (range) {
  //      this.startDate = range.startDate;
  //      this.endDate = range.endDate;
  //    }
  //  })
  //);
  dateRange$ = this.store.select(selectEntireDateRange);

  constructor(private store: Store<DateRangeState>, private datePipe: DatePipe, private piPointsClient: PiPointsClient) { }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
  }

  ngOnInit(): void {
    // Set default dates
    this.dateRange$.subscribe(dateRange => {
      // Call the API whenever dateRange changes
      this.startDate = dateRange['DateRangePicker'].startDate;
      this.endDate = dateRange['DateRangePicker'].endDate;
      this.setupTableUpdates();
      //this.dataChange.next([]);  // Trigger data retrieval
    });
  }

  ngAfterViewInit(): void {
    //this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    setTimeout(() => {
      this.sort.active = 'created';  // Your desired column
      this.sort.direction = 'desc';  // Your desired direction
    }, 0);
  }

  setupTableUpdates() {
    merge(this.dataChange)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.piPointsClient.monthlySentDataToMOE(
            this.startDate,
            this.endDate
          );
        }),
        map(data => {
          this.isLoadingResults = false;
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
    ).subscribe(data => {
      this.dataSource.data = data
    });
  }
}


