import { Action, createReducer, on } from '@ngrx/store';
import * as DateRangePickerActions from './date-range-picker.actions';
import { DateRangePicker } from './date-range-picker.model';
import * as moment from 'moment';

export interface DateRangeState extends DateRangePicker { }



export const initialState: DateRangeState = {
  startDate: moment(moment().startOf('month').toDate()).toDate(),
  endDate: moment(moment().hour(19).minute(0).second(0).millisecond(0).toDate()).toDate(), //.subtract(1, 'days')
};

export const dateRangePickerReducer = createReducer(
  initialState,
  on(DateRangePickerActions.setDateRange, (state, action) => ({ ...state, startDate: action.startDate, endDate: action.endDate }))
);

export function reducer(state: DateRangeState | undefined, action: Action) {
  return dateRangePickerReducer(state, action);
}



